.image-gallery-slides{
    height: 320px;
    width: 320px;
}
.image-gallery-slide, .image-gallery-image {
  max-height: unset;
  height: 100%;
  object-fit: cover;
  cursor: default;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 60px;
    width: 20px;
    color: #005DA3;
}

@media (hover: hover) and (pointer: fine) {
    .image-gallery-icon:hover {
        color: #005DA3;
        padding: 3px;
    }
}

@media (hover: hover) and (pointer: fine) {
    .image-gallery-bullets .image-gallery-bullet.active:hover {
        background: #005DA3;
        padding: 3px;
    }
}

@media (hover: hover) and (pointer: fine) {
    .image-gallery-bullets .image-gallery-bullet.active {
        background: #005DA3;
        border: 1px solid #005DA3;
        padding: 3px;
    }
}

.image-gallery-icon {
    color: #fff;
    transition: all .3s ease-out;
    -webkit-appearance: none;
    appearance: none;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
    -webkit-filter: unset;
    filter: unset;
}
